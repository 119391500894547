import React from 'react';
import Typography from '@material-ui/core/Typography';
import useWidth from '../../Common/useWidth';
import { useHistory } from 'react-router-dom';



export default function LogoName(props: { nombreDelNegocio: string }) {
    const width = useWidth();

    const history = useHistory();

    const nombreLength = width === "xs" ? 18 : 60;

    const nombre = props.nombreDelNegocio ? props.nombreDelNegocio.length > nombreLength ? props.nombreDelNegocio.substr(0, nombreLength) + ".." : props.nombreDelNegocio : "Audiovisuales"

    return (
        <div style={{
            display: "flex",
            alignItems: "center",
            cursor: "pointer"
        }} onClick={() => { history.push("/"); }}>
            <img src="logo192.png" alt="Logo" width="32" height="32" />
            <Typography variant="h6" style={{ color: "#eeeeee", marginLeft: 10 }}>
                {nombre}
            </Typography>
        </div>
    );
}