import React from 'react';

import Footer from '../Common/Footer';
import useWidth from '../Common/useWidth';
import { AudiovisualApuntado } from '../Modelos/AudiovisualApuntado';
import { BusinessInfo } from '../Modelos/BusinessInfo';
import { FolderApuntado } from '../Modelos/FolderApuntado';
import { Tipo } from '../Modelos/Tipo';
import CenterText from './CenterText';
import Search from './FullSearch';
import TopBar from './TopBar/Bar';

export default function HomeScreen(props: {
    businessInfo: BusinessInfo,
    audiovisualesApuntados: Map<string, AudiovisualApuntado>,
    foldersApuntados: Map<string, FolderApuntado>,
    search: (t: Tipo, f: string) => void,
    filtroText: string,
    setFiltroText: (f: string) => void,
    filtroTipo: Tipo,
    setFiltroTipo: (x: Tipo) => void,
    tiposPresentes: Tipo[],
}) {
    const width = useWidth();
    const searchWidth = width === "xs" ? 80 : width === "sm" ? 60 : width === "md" ? 50 : 40;

    return (
        <>
            <TopBar apuntados={props.audiovisualesApuntados.size + props.foldersApuntados.size} nombreDelNegocio={props.businessInfo.Nombre} />
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", }}>
                <div style={{ marginTop: 40 }}>
                    <CenterText marketingTexto={props.businessInfo.MarketingTexto} />
                </div>
                <div style={{ width: searchWidth + "%", marginTop: 30 }}>
                    <Search tiposPresentes={props.tiposPresentes} filtroTipo={props.filtroTipo} setFiltroTipo={props.setFiltroTipo} filtroText={props.filtroText} setFiltroText={props.setFiltroText} search={props.search} />
                </div>
                <Footer direccionDelNegocio={props.businessInfo.Direccion} fixedPosition={true} />
            </div>
        </>
    );
}