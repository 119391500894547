export enum Tipo {
    Indefinido,
    Pelicula,
    Serie,
    Novela,
    Manga,
    Show,
    Dorama,
    Documental,
    Muñes,
    Deporte,
    Humor,
    Concurso,
    Interesante,
    VideoClip,
    Otros,
}

export const Tipos = [
    Tipo.Pelicula,
    Tipo.Serie,
    Tipo.Novela,
    Tipo.Manga,
    Tipo.Show,
    Tipo.Dorama,
    Tipo.Documental,
    Tipo.Muñes,
    Tipo.Deporte,
    Tipo.Humor,
    Tipo.Concurso,
    Tipo.Interesante,
    Tipo.VideoClip,
    Tipo.Otros]

export function TipoText(tipo: Tipo) {
    if (tipo === Tipo.Pelicula) {
        return "Películas";
    }

    if (tipo === Tipo.Serie) {
        return "Series";
    }

    if (tipo === Tipo.Novela) {
        return "Novelas";
    }

    if (tipo === Tipo.Show) {
        return "Shows";
    }

    if (tipo === Tipo.Dorama) {
        return "Doramas";
    }

    if (tipo === Tipo.Manga) {
        return "Manga";
    }

    if (tipo === Tipo.Documental) {
        return "Documentales";
    }

    if (tipo === Tipo.Muñes) {
        return "Muñes";
    }

    if (tipo === Tipo.Deporte) {
        return "Deporte";
    }

    if (tipo === Tipo.Humor) {
        return "Humor";
    }

    if (tipo === Tipo.Concurso) {
        return "Concursos";
    }

    if (tipo === Tipo.Interesante) {
        return "Interesante";
    }

    if (tipo === Tipo.VideoClip) {
        return "Video Clip";
    }

    if (tipo === Tipo.Otros) {
        return "Otros";
    }

    //caso Tipo.Indefinido
    return "";
}

export function TipoParseFromString(tipoString: string): Tipo {
    return Tipos.find(x => TipoText(x) === tipoString) || Tipo.Pelicula;
}

export function TipoParseFromRaw(tipoString: string) {
    if (!tipoString) {
        return Tipo.Otros;
    }

    if ("Pel" === tipoString) {
        return Tipo.Pelicula;
    }

    if ("Doc" === tipoString) {
        return Tipo.Documental;
    }

    if ("Ser" === tipoString) {
        return Tipo.Serie;
    }

    if ("Nov" === tipoString) {
        return Tipo.Novela;
    }

    if ("Man" === tipoString) {
        return Tipo.Manga;
    }

    if ("Hum" === tipoString) {
        return Tipo.Humor;
    }

    if ("Conc" === tipoString) {
        return Tipo.Concurso;
    }

    if ("Mun" === tipoString) {
        return Tipo.Muñes;
    }
    if ("Dor" === tipoString) {
        return Tipo.Dorama;
    }

    if ("Dep" === tipoString) {
        return Tipo.Deporte;
    }

    if ("Show" === tipoString) {
        return Tipo.Show;
    }

    if ("Int" === tipoString) {
        return Tipo.Interesante;
    }

    if ("VidC" === tipoString) {
        return Tipo.VideoClip;
    }

    return Tipo.Indefinido;
}