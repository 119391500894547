import React from 'react';
import { Typography, Slide } from '@material-ui/core';

import DirectionsRunIcon from '@material-ui/icons/DirectionsRun';
import { ReplaceAll } from '../Utils/StringUtils';

export default function Footer(props: { fixedPosition: boolean, direccionDelNegocio?: string }) {
    let iconHeight = window.innerHeight > 700
        ? window.innerHeight - 560
        : window.innerHeight > 500
            ? 160
            : 80;

    iconHeight = Math.min(iconHeight, 300);

    const fixedFooter = window.innerHeight > 700;

    var direccion = props.direccionDelNegocio ? props.direccionDelNegocio : "De aquí del Barrio.";
    direccion = ReplaceAll(direccion, "&&&", "\n");

    return (
        <Slide direction="right" in mountOnEnter>
            <div style={{
                position: fixedFooter ? "fixed" : "relative",
                left: fixedFooter ? 0 : "",
                right: fixedFooter ? 0 : "",
                bottom: fixedFooter ? 0 : "",
                width: "100%"
            }}>
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyItems: "center",
                    alignItems: "center"
                }}>
                    <DirectionsRunIcon htmlColor="#dddddd" style={{ marginTop: 0, fontSize: iconHeight }} />
                    {true && <Typography style={{ fontWeight: "lighter", color: "#dddddd", fontSize: 11, marginTop: 0, marginBottom: -1, whiteSpace: "pre-wrap", textAlign: "center" }}>
                        {direccion}
                    </Typography>}
                    {true && <Typography variant="h6" style={{ fontWeight: "bolder", marginTop: -6, color: "#EF9A9A", fontSize: 30 }}>
                        Visítanos
                    </Typography>}
                </div>
            </div>
        </Slide>
    );
}