import React from 'react';
import { Typography } from '@material-ui/core';

import { Slide } from "@material-ui/core/";

export default function CenterText(props: { marketingTexto?: string }) {
    return (
        <Slide direction="right" in mountOnEnter>
            <div style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                width: "100%"
            }}>
                <Typography variant="h2" style={{ fontWeight: "bolder", color: "#888888" }}>
                    Catálogo
                </Typography>
                <Typography variant="h6" style={{ fontWeight: "lighter", color: "#aaaaaa", marginLeft: 30, marginRight: 30, textAlign: "center" }}>
                    {props.marketingTexto ? props.marketingTexto : "Tenemos lo que te gusta."}
                </Typography>
            </div>
        </Slide>
    );
}