export function EditionDistance(a: string, b: string): number {
    if (!a && !b) {
        return 0;
    }

    if (!a) {
        return b.length;
    }

    if (!b) {
        return a.length;
    }

    return DynamicEditionDistance(a, b);
}

function DynamicEditionDistance(a: string, b: string): number {
    var length0 = a.length + 1;
    var length1 = b.length + 1;
    var m: number[][] = new Array(length0);

    for (let i = 0; i < length0; i++) {
        m[i] = new Array(length1);
        for (let j = 0; j < length1; j++) {
            m[i][j] = 0;
        }
    }

    for (let i = 0; i < length1; i++) {
        m[length0 - 1][i] = length1 - i - 1;
    }

    for (let i = 0; i < length0; i++) {
        m[i][length1 - 1] = length0 - i - 1;
    }

    for (let i = length0 - 2; i >= 0; i--) {
        for (var j = length1 - 2; j >= 0; j--) {
            m[i][j] =
                a.charAt(i) === b.charAt(j)
                    ? m[i + 1][j + 1]
                    : Math.min(m[i + 1][j + 1], m[i][j + 1]) + 1;
        }
    }

    return m[0][0];
}

export function AreSimilar(a: string, b: string, umbral: number) {
    if (!a && !b) {
        return false;
    }

    if (!a) {
        return false;
    }

    if (!b) {
        return false;
    }

    var minLen = Math.min(a.length, b.length);

    var umbralInt = (umbral * minLen);
    if (umbralInt <= 0) {
        umbralInt = 1;
    }

    var editionDistance = EditionDistance(a, b);
    return editionDistance <= umbralInt;
}