import React from "react";
import { ThemeProvider  } from "@material-ui/core/styles";
import { createMuiTheme } from "@material-ui/core/styles";
import {grey} from "@material-ui/core/colors";

const appTheme = createMuiTheme({
  palette: {
    primary: {
      main: grey[800]
    },
    secondary: {
      main: "#D85046"
    },
    background: {
      default: "#ffffff"
    }
  }
});

export default function AppThemer(props) {
    return (
      <ThemeProvider  theme={appTheme}>
        {props.children}
      </ThemeProvider >
    );
  }



