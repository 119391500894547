import React, { ChangeEvent, ChangeEventHandler, MouseEventHandler, useState } from 'react';

import Fab from '@material-ui/core/Fab';
import { IconButton, TextField, Slide, Tooltip, Snackbar, SnackbarContent, SnackbarCloseReason, Tabs, Tab } from '@material-ui/core';

import SearchIcon from '@material-ui/icons/Search';

import CloseIcon from '@material-ui/icons/Close';
import HelpIcon from '@material-ui/icons/Help';

import { Kitchen } from '@material-ui/icons';
import { Tipo, Tipos, TipoText } from '../Modelos/Tipo';

const color = "#D85046";

export default function Search(props: {
    search: (t: Tipo, f: string) => void,
    filtroText: string,
    setFiltroText: (f: string) => void,
    filtroTipo: Tipo,
    setFiltroTipo: (x: Tipo) => void,
    tiposPresentes: Tipo[],
}) {
    const [snackText, setSnackText] = useState("");

    const onSearch: MouseEventHandler<HTMLButtonElement> | undefined = () => {
        if (!props.filtroText) {
            props.search(props.filtroTipo, props.filtroText);
        } else {
            props.search(Tipo.Indefinido, props.filtroText);
        }
    };

    const onCloseSnack: React.MouseEventHandler<HTMLButtonElement> | undefined = () => {
        setSnackText("");
    };

    const onCloseSnack2: ((event: React.SyntheticEvent<any, Event>, reason: SnackbarCloseReason) => void) | undefined = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setSnackText("");
    };


    const handleCleanTexto: MouseEventHandler<HTMLSpanElement> | undefined = () => {
        props.setFiltroText("");
    };

    const handleTextChange: ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement> | undefined = (event: ChangeEvent<HTMLTextAreaElement>) => {
        if (event.target.value.length < 50) {
            props.setFiltroText(event.target.value);
        }
    };

    var tipos = props.tiposPresentes;

    if (!tipos || tipos.length === 0) {
        tipos = Tipos;
    }

    tipos = tipos.sort((x, y) => x - y);

    const tiposTexts = tipos.map(t => TipoText(t));

    const handleTipoChange: ((event: React.ChangeEvent<{}>, value: any) => void) | undefined = (event, newValue) => {
        var newTipo = tipos[newValue];
        props.search(newTipo, props.filtroText);
    };

    const tabInt = tipos.indexOf(props.filtroTipo);

    return (
        <Slide direction="right" in mountOnEnter>
            <div style={{ marginBottom: 60 }}>
                <div style={{ display: "flex", flexDirection: "column", marginLeft: 0 }}>
                    <Tooltip title="Tipo de Audiovisual.">
                        <div style={{ width: "100%" }}>
                            <Tabs
                                value={tabInt}
                                onChange={handleTipoChange}
                                variant="scrollable"
                                textColor="secondary"
                                TabIndicatorProps={{ style: { height: 5 } }}
                                TabScrollButtonProps={{ style: { color: "#cccccc" } }}
                                scrollButtons="off"
                            >
                                {tiposTexts.map((tipo, index) => {
                                    return (
                                        <Tab label={tipo} key={index} style={{ minWidth: 10, fontSize: 18, fontWeight: "bolder", color: index === tabInt ? color : "#dddddd" }} />
                                    );
                                })}
                            </Tabs>
                        </div>
                    </Tooltip>
                </div>
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: 10, marginLeft: 10, marginRight: 20 }}>
                    <div style={{ display: "flex", flexDirection: "column", marginTop: 12, marginLeft: 0 }}>
                        <Kitchen style={{ color: "#dddddd", marginLeft: 8, fontSize: 50, marginBottom: 12 }} />
                        <Tooltip title="Filtrar por palabras.">
                            <TextField
                                variant="standard"
                                size="small"
                                placeholder="Por Texto"
                                onChange={handleTextChange}
                                value={props.filtroText}
                                style={{ width: 160, }}
                                InputProps={{
                                    endAdornment: props.filtroText && <IconButton size="small" component="span" onClick={handleCleanTexto} >
                                        <CloseIcon htmlColor="#dddddd" style={{ width: 16, height: 16 }} />
                                    </IconButton>,
                                    startAdornment: <SearchIcon htmlColor="#dddddd" style={{ width: 16, height: 16, marginLeft: 3, marginRight: 3 }} />
                                }}
                            />
                        </Tooltip>
                    </div>
                    <div style={{ marginBottom: 16, marginRight: 6 }}>
                        <Tooltip title="Puede hacer las búsquedas sin Conexión.">
                            <Fab color="secondary" aria-label="buscar" onClick={onSearch} style={{ width: 86, height: 86 }} size="large">
                                <SearchIcon style={{ fontSize: 58 }} />
                            </Fab>
                        </Tooltip>
                    </div>
                </div>
                <Snackbar
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left"
                    }}
                    open={snackText !== null && snackText.length > 0}
                    autoHideDuration={2000}
                    onClose={onCloseSnack2}
                >
                    <SnackbarContent style={{ backgroundColor: "#aaaaaa" }}
                        message={
                            <span
                                style={{
                                    display: "flex",
                                    alignItems: "center"
                                }}

                            >
                                <HelpIcon style={{ marginRight: 10 }} color="secondary" />
                                {snackText}
                            </span>}
                        action={
                            <>
                                <IconButton size="small" color="secondary" onClick={onCloseSnack}>
                                    <CloseIcon fontSize="small" />
                                </IconButton>
                            </>
                        }
                    />
                </Snackbar>
            </div >
        </Slide>
    );
}