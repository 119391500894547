import { Button, Slide, Typography } from '@material-ui/core';
import React, { MouseEventHandler } from 'react';
import BackButton from '../AudiovisualesScreen/BackButton';
import useWidth from '../Common/useWidth';
import TopBar from '../HomeScreen/TopBar/Bar';
import { AudiovisualApuntado } from '../Modelos/AudiovisualApuntado';
import { BusinessInfo } from '../Modelos/BusinessInfo';
import { FolderApuntado } from '../Modelos/FolderApuntado';
import AudiovisualApuntadoItem from './AudiovisualApuntadoItem';
import FolderApuntadoItem from './FolderApuntadoItem';
import LocalMoviesIcon from '@material-ui/icons/LocalMovies';
import { useHistory } from 'react-router';

export default function ApuntadoScreen(props: {
    businessInfo: BusinessInfo,
    audiovisualesApuntados: Map<string, AudiovisualApuntado>,
    foldersApuntados: Map<string, FolderApuntado>,
    removeFolderApuntado: (a: FolderApuntado) => void,
    removeAudiovisualApuntado: (a: AudiovisualApuntado) => void,
    desapuntarTodo: () => void,
}) {
    const currentWidth = useWidth();
    const margin = currentWidth === "xs" ? 10 : currentWidth === "sm" ? 60 : currentWidth === "md" ? 100 : 180;
    const width = currentWidth === "xs" ? 95 : currentWidth === "sm" ? 70 : currentWidth === "md" ? 60 : 50;

    const totalAudiovisuales = Array.from(props.foldersApuntados.values()).reduce((x, y) => { return y.TotalAudiovisuales + x; }, 0) + props.audiovisualesApuntados.size;

    const totalSize = Array.from(props.foldersApuntados.values()).reduce((x, y) => { return y.TotalSizeInMb + x; }, 0) + Array.from(props.audiovisualesApuntados.values()).reduce((x, y) => { return y.SizeMB + x; }, 0);

    const empty = totalAudiovisuales === 0;

    const history = useHistory();

    const handleDesapuntarTodo: MouseEventHandler<HTMLButtonElement> = () => {
        props.desapuntarTodo();
    };

    return (
        <>
            <TopBar nombreDelNegocio={props.businessInfo.Nombre} apuntados={props.audiovisualesApuntados.size + props.foldersApuntados.size} />
            {!empty &&
                <Slide direction="right" in mountOnEnter>
                    <div style={{
                        marginLeft: margin, width: width + "%", marginTop: 30, marginBottom: 80
                    }}>
                        <Button color="secondary" style={{ marginTop: 6, marginLeft: 12 }} variant="outlined" onClick={() => { history.push("/"); }}>Inicio</Button>
                        <div style={{ marginTop: 20 }}>
                            {Array.from(props.audiovisualesApuntados.values()).map((audiovisual: AudiovisualApuntado, index: number) => {
                                if (!audiovisual) {
                                    return null;
                                }

                                return (
                                    <AudiovisualApuntadoItem
                                        audiovisualApuntado={audiovisual}
                                        key={audiovisual.FullPath}
                                        removeApuntado={props.removeAudiovisualApuntado}
                                    />
                                );
                            })}
                            {Array.from(props.foldersApuntados.values()).map((folder: FolderApuntado, index: number) => {
                                if (!folder) {
                                    return null;
                                }

                                return (
                                    <FolderApuntadoItem
                                        folderApuntado={folder}
                                        key={folder.FullPath}
                                        removeApuntado={props.removeFolderApuntado}
                                    />
                                );
                            })}
                        </div>

                        <div style={{ marginTop: 60, marginLeft: 20 }}>
                            <Typography variant="subtitle1" style={{ color: "#aaaaaa" }}>
                                {"Audiovisuales " + totalAudiovisuales}
                            </Typography>
                            <Typography variant="h4" style={{ fontWeight: "bolder", color: "#aaaaaa" }}>
                                {(totalSize / 1024).toFixed(2) + " GB"}
                            </Typography>
                        </div>
                        <div style={{ display: "flex", flexDirection: "row-reverse" }}>
                            <Button onClick={handleDesapuntarTodo} variant="text" style={{ color: "#dddddd", textTransform: "none", marginRight: 20, marginTop: -32 }}>
                                Desapuntar Todo
                        </Button>
                        </div>
                    </div>
                </Slide>
            }
            {empty &&
                <Slide direction="right" in mountOnEnter>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            marginTop: 100,
                            alignItems: "center",
                            justifyContent: "center",
                            marginLeft: currentWidth === "xs" ? 0 : 20
                        }}
                    >
                        <LocalMoviesIcon style={{ fontSize: 80, color: "#eeeeee" }} />
                        <Typography style={{ color: "#dddddd" }} variant="h6">
                            Nada Apuntado
                            </Typography>
                        <Button color="secondary" style={{ marginTop: 30 }} variant="outlined" onClick={() => { history.push("/"); }}>Explorar</Button>
                    </div>
                </Slide>
            }
        </>
    );
}