export function GetRandomColor(audiovisualName: string) {
    if (!audiovisualName || audiovisualName.length < 3) {
        return "#dddddd";
    }

    var min = 140;
    var max = 210;
    var red = min + ((audiovisualName.charCodeAt(0) * 15) % (max - min));
    var green = min + ((audiovisualName.charCodeAt(1) * 13) % (max - min));
    var blue = min + ((audiovisualName.charCodeAt(2) * 21) % (max - min));;

    return "#" + ColorRGB(red, green, blue);
}

function ColorRGB(r: number, g: number, b: number) {
    var red = toHex(r);
    var green = toHex(g);
    var blue = toHex(b);

    return red + green + blue;
}

function toHex(i: number) {
    var hex = Number(i).toString(16);
    if (hex.length < 2) {
        return "0" + hex;
    }

    return hex;
}