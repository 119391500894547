
//porque el ReplaceAll de JS, no me funciona en iOS
export function ReplaceAll(s: string, pattern: string, replacement: string) {
    if (!s) {
        return s;
    }

    while (s.indexOf(pattern) >= 0) {
        s = s.replace(pattern, replacement);
    }

    return s;
}

export function ReplaceAllRegex(s: string, pattern: RegExp, replacement: string) {
    if (!s) {
        return s;
    }

    while (s.match(pattern)) {
        s = s.replace(pattern, replacement);
    }

    return s;
}