import React from 'react';
import LogoName from './LogoName';

import { AppBar, Toolbar, } from '@material-ui/core';
import ApuntadoButton from './ApuntadoButton';


export default function TopBar(props: {
    nombreDelNegocio: string,
    apuntados: number,
}) {
    return (
        <AppBar position="static" >
            <Toolbar>
                <div style={{ flexGrow: 1 }}>
                    <LogoName nombreDelNegocio={props.nombreDelNegocio} />
                </div>
                <div style={{ marginTop: 6}}>
                    <ApuntadoButton
                        apuntados={props.apuntados}
                    />
                </div>
            </Toolbar>
        </AppBar>
    );
}