import { ReplaceAll } from "../Utils/StringUtils";
import { Audiovisual } from "./Audiovisual";
import { BusinessInfo } from "./BusinessInfo";
import { Folder } from "./Folder";
import { Tipo, TipoParseFromRaw, TipoText } from "./Tipo";

export class AudiovisualesDataLoader {
    Data: Folder = new Folder();

    AddFolder(tipo: Tipo, audiovisualsFolder: Audiovisual[], parent: string) {
        if (!audiovisualsFolder || audiovisualsFolder.length === 0) {
            return;
        }

        var folder = this.FindOrCreateFolder(tipo, parent);
        folder.Audiovisuales.push(...audiovisualsFolder);

        var size = 0;
        audiovisualsFolder.forEach(audiovisual => {
            size += audiovisual.SizeMB;
            audiovisual.FolderParent = folder;
        });

        var current: Folder | null = folder;
        while (current !== null) {
            current.TotalSizeInMb += size;
            current.TotalAudiovisuales += audiovisualsFolder.length;
            current = current.ParentFolder;
        }
    }

    FindOrCreateFolder(tipo: Tipo, path: string): Folder {
        var folder = this.Data.Folders.get(TipoText(tipo));
        if (!folder) {
            folder = new Folder();
            folder.Nombre = TipoText(tipo);
            this.Data.Folders.set(TipoText(tipo), folder);
        }

        var splitted = path.split("/");
        return this.FindOrCreateFolderRecursive(splitted, folder);
    }

    FindOrCreateFolderRecursive(paths: string[], folder: Folder): Folder {
        var currentFolderName = paths[0];
        var currentFolder = folder.Folders.get(currentFolderName);
        if (!currentFolder) {
            currentFolder = new Folder();
            currentFolder.Nombre = currentFolderName;
            folder.Folders.set(currentFolderName, currentFolder);
            currentFolder.ParentFolder = folder;
        }

        if (paths.length === 1) {
            return currentFolder;
        }

        paths.shift();
        return this.FindOrCreateFolderRecursive(paths, currentFolder);
    }

    Load(rawData: any[],
        setFullData: (x: Folder) => void,
        setFilteredData: (x: Folder) => void
    ) {
        rawData.forEach(element => {
            var currentFolder: Audiovisual[] = [];
            var currentTipo: Tipo | null = null;
            var currentParent = "";


            element.AudioVisuales.forEach((rawAudiovisual: { N: string; T: string; D: string; P: any; C: string; }) => {
                var currentAudiovisual = new Audiovisual();
                currentAudiovisual.Nombre = this.CleanNombre(rawAudiovisual.N);
                currentAudiovisual.SizeMB = parseInt(rawAudiovisual.T) || 0;
                currentAudiovisual.Duracion = rawAudiovisual.D;

                var parent = this.CleanNombre(rawAudiovisual.P);
                if (!parent) {
                    parent = "Otros";
                }

                if (currentTipo === null) {
                    currentTipo = TipoParseFromRaw(rawAudiovisual.C);
                }

                if (currentParent == null) {
                    currentParent = parent;
                }

                if (currentParent === parent) {
                    currentFolder.push(currentAudiovisual);
                } else {
                    this.AddFolder(currentTipo, currentFolder, currentParent);
                    currentFolder = [];
                    currentFolder.push(currentAudiovisual);
                    currentParent = parent;
                    currentTipo = TipoParseFromRaw(rawAudiovisual.C);
                }
            });

            if (currentTipo) {
                this.AddFolder(currentTipo, currentFolder, currentParent);
            }
        });

        setFullData(this.Data);
        setFilteredData(this.Data);
    }

    CleanNombre(nombre: string) {
        var toRemove = ["#"];
        var result = nombre;

        for (let index = 0; index < toRemove.length; index++) {
            const element = toRemove[index];
            result = ReplaceAll(result, element, "");
        }

        return result;
    }
}