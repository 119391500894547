import { useState, MouseEventHandler, TouchEventHandler } from 'react';

import FolderIcon from '@material-ui/icons/Folder';
import { Slide, Typography, Button, Divider } from "@material-ui/core/";
import CheckIcon from '@material-ui/icons/Check'
import { Folder } from '../Modelos/Folder';
import { useHistory } from 'react-router-dom';
import { PathSeparator } from '../Modelos/Search';
import { GetRandomColor } from '../Utils/ColorHelper';
import { AudiovisualApuntado } from '../Modelos/AudiovisualApuntado';
import { FolderApuntado } from '../Modelos/FolderApuntado';
import { AddFolderApuntadaStorage, RemoveFolderApuntadoStorage } from '../Modelos/ApuntadoStorageHelper';
import { updateCarritoNumberManual } from '../Utils/Utils';


export default function FolderItem(props: {
    folder: Folder,
    audiovisualesApuntados: Map<string, AudiovisualApuntado>,
    foldersApuntados: Map<string, FolderApuntado>,
}) {

    const history = useHistory();

    const [apuntado, setApuntado] = useState(props.folder.GetEstaApuntada(props.foldersApuntados));

    const [mouseDownDate, setMouseDownDate] = useState(0);

    const handleMouseDown: MouseEventHandler<HTMLButtonElement> = () => {
        setMouseDownDate(new Date().getTime());
    };

    const handleMouseUp: MouseEventHandler<HTMLButtonElement> = () => {
        mouseUp();
    };

    const mouseUp = () => {
        var delta = new Date().getTime() - mouseDownDate;
        if (delta > 1000) { //long click
            if (apuntado) {
                props.foldersApuntados.delete(props.folder.FullPath());
                RemoveFolderApuntadoStorage(props.folder.FullPath());
            } else {
                var folderApuntado = props.folder.BuildFolderApuntado();
                props.foldersApuntados.set(props.folder.FullPath(), folderApuntado);
                AddFolderApuntadaStorage(folderApuntado);
            }
            updateCarritoNumberManual(props.audiovisualesApuntados.size + props.foldersApuntados.size);
            setApuntado(!apuntado);
        } else {//click
            history.push("/sub?full=1&path=" + encodeURI(props.folder.FullPath(PathSeparator)));
        }
    };

    const handleTouchStart: TouchEventHandler<HTMLButtonElement> = (e) => {
        setMouseDownDate(new Date().getTime());
    };

    const handleTouchEnd: TouchEventHandler<HTMLButtonElement> = (e) => {
        mouseUp();
    };

    return (
        <div>
            <Slide direction="right" in mountOnEnter>
                <div>
                    <Button
                        variant="text"
                        fullWidth
                        color="primary"
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            paddingRight: 0, paddingTop: 10, paddingLeft: 0, paddingBottom: 10,
                            textTransform: "none"
                        }}
                        onMouseDown={handleMouseDown}
                        onMouseUp={handleMouseUp}
                        onTouchStart={handleTouchStart}
                        onTouchEnd={handleTouchEnd}
                    >
                        <div style={{ flex: "auto", display: "flex", flexDirection: "row", alignItems: "stretch", paddingLeft: 8 }}>
                            <div style={{ flex: "0 0 auto", }}>
                                <FolderIcon htmlColor={GetRandomColor(props.folder.GetNombreLongNotUpperToShow())} style={{ marginTop: 2, marginRight: 0, fontSize: 30 }} />
                            </div>
                            <div style={{ flex: "1 1 auto", display: "flex", flexDirection: "column", marginLeft: 8 }}>
                                <div style={{ display: "flex", justifyContent: "flex-start", marginTop: 0, marginRight: 40 }}>
                                    <Typography variant="h6" align="left" style={{ fontSize: 14, fontWeight: "lighter", color: "#999999" }}>
                                        {props.folder.GetNombreLongNotUpperToShow()}
                                    </Typography>
                                </div>
                                <div style={{ display: "flex", alignItems: "center", marginTop: 1 }}>
                                    <Typography style={{ fontSize: 10, fontWeight: "lighter", color: "#cccccc" }}>
                                        {props.folder.GetTotalSizeText()}
                                    </Typography>
                                    <Typography style={{ fontSize: 10, fontWeight: "lighter", color: "#cccccc", marginLeft: 10 }}>
                                        {props.folder.GetCantidadDeAudiovisuales()}
                                    </Typography>
                                </div>
                                {props.folder.EsContainerDeSerieONovela() &&
                                    <div style={{ display: "flex", flexDirection: "row", marginBottom: -10, marginRight: 0 }}>
                                        <Button style={{ padding: 0, zIndex: 100000, marginLeft: -16, marginRight: -16 }} target="_blank" href={props.folder.GetSinopsisURLToSearch()}>
                                            <Typography style={{ fontSize: 8, color: "#dddddd", textTransform: "none", margin: 0 }}>
                                                Sinopsis
                                            </Typography>
                                        </Button>
                                        <Button style={{ marginRight: -16, textTransform: "none", zIndex: 100000 }} target="_blank" href={props.folder.GetImagesURLToSearch()}>
                                            <Typography style={{ fontSize: 8, color: "#dddddd", margin: 0, padding: 0 }}>
                                                Imágenes
                                            </Typography>
                                        </Button>
                                    </div>}
                            </div>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <div style={{ flex: 1, display: "flex", flexDirection: "row-reverse", marginRight: 15 }}>
                                    {apuntado && <CheckIcon style={{ fontSize: 22, color: "#EF9A9A" }} />}
                                </div>
                            </div>
                        </div>
                    </Button>
                    <Divider light style={{ marginLeft: 8, marginRight: 8 }} />
                </div>
            </Slide>
        </div>
    );
}

