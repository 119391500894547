import { NoUpperCase } from "../Utils/Utils";

export class FolderApuntado {
    FullPath = "";

    TotalAudiovisuales = 0;

    TotalSizeInMb = 0;

    constructor(x: any = {}) {
        this.FullPath = (x && x.FullPath) || "";
        this.TotalAudiovisuales = (x && x.TotalAudiovisuales) || 0;
        this.TotalSizeInMb = (x && x.TotalSizeInMb) || 0;
    }

    GetNombreToShow() {
        return NoUpperCase(this.FullPath);
    }

    GetTotalSizeText() {
        return "" + (this.TotalSizeInMb / 1024).toFixed(2) + " GB";
    }

    GetCantidadDeAudiovisuales() {
        return "Audiovisuales " + this.TotalAudiovisuales;

    }

    EstaApuntada(foldersApuntadas: Map<string, FolderApuntado>): boolean {
        return foldersApuntadas.has(this.FullPath);
    }
}
