import { IsLetter, NoUpperCase, RemoveExtension } from "../Utils/Utils";
import { AudiovisualApuntado } from "./AudiovisualApuntado";
import { Folder } from "./Folder";
import { FolderApuntado } from "./FolderApuntado";
import { Tipo, TipoText } from "./Tipo";

export class Audiovisual {
    Nombre = "";

    SizeMB = 0;

    Duracion = "";

    FolderParent: Folder = new Folder();

    GetNombreLongNotUpperToShow(): string {
        return NoUpperCase(this.Nombre);
    }

    GetTipo(): Tipo {
        return this.FolderParent.GetTipo();
    }

    GetNombreQuery(): string {
        var nombre = this.GetNombreLongNotUpperToShow();

        if (!nombre) {
            return "";
        }

        var extensionInicio: number = nombre.lastIndexOf('.');
        if (extensionInicio < 0) {
            extensionInicio = nombre.length;
        }

        var result: string = "";
        var opened: number = 0;
        for (var i = 0; i < extensionInicio; i++) {
            var current = nombre.charAt(i);

            if (current === ')' || current === ']' || current === '}') {
                if (opened > 0) {
                    opened--;
                }
                continue;
            }

            if (current === '(' || current === '[' || current === '{') {
                opened++;
                continue;
            }

            if (opened > 0) {
                continue;
            }

            if (IsLetter(current)) {
                if (!((i > 0 && !IsLetter(nombre.charAt(i - 1))) && (i < extensionInicio - 1 && !IsLetter(nombre.charAt(i + 1))))) {
                    result += ("" + current);
                } else {
                    if (current === 'a' || current === 'y' || current === 'e' || current === 'o') {
                        result += ("" + current);
                    } else {
                        result += (" ");
                    }
                }
            } else {
                result += (" ");
            }
        }

        return result.toString();
    }


    GetImagesURLToSearch(): string {
        var tipo = this.GetTipo();

        return "https://www.google.com/search?hl=es&tbm=isch&q="
            + (tipo !== Tipo.Indefinido && tipo !== Tipo.Interesante ? TipoText(tipo) + " " : "")
            + this.GetNombreQuery();
    }

    GetSinopsisURLToSearch(): string {
        var tipo = this.GetTipo();

        return "https://www.google.com/search?hl=es&q="
            + (tipo !== Tipo.Indefinido && tipo !== Tipo.Interesante ? TipoText(tipo) + " " : "")
            + this.GetNombreQuery() + " Sinopsis";
    }

    GetGBSizeString(): string {
        if (this.SizeMB <= 0) {
            return "";
        }

        try {
            return (this.SizeMB / 1024).toFixed(2) + " GB";
        } catch (e) {

        }

        return "";
    }

    GetDuracionString(): string {
        if (!this.Duracion || this.Duracion.length > 20) {
            return "";
        }

        return this.Duracion;
    }

    ParseMBSizeValue(sizeMB: string): number {
        try {
            return parseInt(sizeMB);
        } catch (e) {

        }

        return 0;
    }

    FullPath(): string {
        var result: string = "";
        var stackParents: string[] = [];
        var current: Folder | null = this.FolderParent;
        while (current != null) {
            stackParents.push(current.Nombre);
            current = current.ParentFolder;
        }

        while (stackParents.length > 0) {
            result += (stackParents.pop() + " / ");
        }

        result += this.Nombre;

        return result;
    }

    GetEstaApuntado(audiovisualesApuntados: Map<string, AudiovisualApuntado>, foldersApuntados: Map<string, FolderApuntado>): boolean {
        if (foldersApuntados.size === 0 && audiovisualesApuntados.size === 0) {
            return false;
        }

        var paths: string[] = [];
        var current: Folder | null = this.FolderParent;
        while (current != null) {
            paths.unshift(current.Nombre);
            current = current.ParentFolder;
        }

        var fullPath = "";
        while (paths.length > 0) {
            var currentPath = paths[0];
            paths.shift();
            fullPath += (currentPath + " / ");
            if (foldersApuntados.has(fullPath.toString())) {
                return true;
            }
        }

        fullPath += this.Nombre;
        return audiovisualesApuntados.has(fullPath);
    }

    GetEstaApuntadoParent(foldersApuntados: Map<string, FolderApuntado>): boolean {
        if (foldersApuntados.size === 0) {
            return false;
        }

        return this.FolderParent != null && this.FolderParent.GetEstaApuntada(foldersApuntados);
    }

    GetEstoyApuntadoYoDirectamente(audiovisualesApuntados: Map<string, AudiovisualApuntado>): boolean {
        if (audiovisualesApuntados.size === 0) {
            return false;
        }

        return audiovisualesApuntados.has(this.FullPath());
    }


    BuildAudiovisualApuntado(): AudiovisualApuntado {
        var result: AudiovisualApuntado = new AudiovisualApuntado();
        result.FullPath = this.FullPath();
        result.Duracion = this.Duracion;
        result.SizeMB = this.SizeMB;

        return result;
    }

    GetNombreSinExt(): string {
        return RemoveExtension(this.Nombre);
    }

    EsCapitulo(): boolean {
        var lower: string = this.GetNombreSinExt();

        if (lower.indexOf("cap") >= 0) {
            return true;
        }

        if (lower.indexOf("episodio") >= 0) {
            return true;
        }

        if (lower.match("\\d( )?x( )?\\d")) {
            return true;
        }

        if (lower.match("\\d\\d^")) {
            return true;
        }

        lower = lower
            .replace(" ", "")
            .replace("x", "")
            .replace("temporada", "")
            .replace("temp", "")
            .replace("(", "")
            .replace(")", "")
            .replace("[", "")
            .replace("]", "")
            .replace("-", "")
            ;

        for (var i = 0; i < lower.length; i++) {
            if (lower.charAt(i) === '.') {
                return true;
            }

            if (IsLetter(lower.charAt(i))) {
                return false;
            }
        }

        return true;
    }

    FullPathToShow(): string {
        return NoUpperCase(this.FullPath());
    }

}

export function OrdenarAudiovisualesPorNombre(audiovisuales: Audiovisual[]) {
    audiovisuales.sort((a, b) => {
        return a.Nombre === b.Nombre
            ? 0
            : a.Nombre > b.Nombre
                ? 1
                : -1;
    });
}