import { Slide, Typography, Button, Link } from '@material-ui/core';
import { useEffect, useState } from 'react';
import CenteredLoading from '../Common/CenteredLoading';
import useWidth from '../Common/useWidth';
import TopBar from '../HomeScreen/TopBar/Bar';
import { Audiovisual } from '../Modelos/Audiovisual';
import { BusinessInfo } from '../Modelos/BusinessInfo';
import { Folder } from '../Modelos/Folder';
import { FindFolderByFullPath } from '../Modelos/Search';
import { Tipo } from '../Modelos/Tipo';
import useQuery from '../Utils/useQuery';
import AudiovisualItem from './AudiovisualItem';
import FolderItem from './FolderItem';
import BackButton from './BackButton';
import { AudiovisualApuntado } from '../Modelos/AudiovisualApuntado';
import { FolderApuntado } from '../Modelos/FolderApuntado';
import { useHistory } from 'react-router';

export function AudiovisualesScreen(props: {
    businessInfo: BusinessInfo,
    apuntados: number,
    loadingFullData: boolean,
    searching: boolean,
    filteredData: Folder,
    fullData: Folder,
    search: (t: Tipo, f: string) => void,
    audiovisualesApuntados: Map<string, AudiovisualApuntado>,
    foldersApuntados: Map<string, FolderApuntado>,
}) {

    const history = useHistory();

    const [currentFolder, setCurrentFolder] = useState<Folder>(new Folder());

    let query = useQuery();

    var fullPath = query.get("path") || "";

    var useFullData = query.get("full") || "";

    var filtro = query.get("filtro") || "";

    useEffect(() => {
        setCurrentFolder(FindFolderByFullPath(useFullData ? props.fullData : props.filteredData, fullPath));
    }, [props.filteredData, props.fullData, fullPath, useFullData]);

    const currentWidth = useWidth();
    const margin = currentWidth === "xs" ? 10 : currentWidth === "sm" ? 60 : currentWidth === "md" ? 100 : 180;
    const width = currentWidth === "xs" ? 95 : currentWidth === "sm" ? 70 : currentWidth === "md" ? 60 : 50;


    return (
        <>
            <TopBar nombreDelNegocio={props.businessInfo.Nombre} apuntados={props.apuntados} />
            <div style={{
                marginLeft: margin, width: width + "%", marginTop: 30, marginBottom: 80
            }}>
                {!props.searching && !props.loadingFullData && currentFolder.NoEmpty() &&
                    <Slide direction="right" in mountOnEnter>
                        <div style={{ marginTop: -15, display: "flex", justifyContent: "space-between" }}>
                            <BackButton />
                        </div>
                    </Slide>}
                {!props.searching && !props.loadingFullData && currentFolder.NoEmpty() &&
                    <Slide direction="right" in mountOnEnter>
                        <div style={{ marginTop: 30, marginBottom: 8, marginLeft: 10, marginRight: 10, display: "flex", flexDirection: "column", }}>
                            <Typography variant="body1" style={{ color: "#aaaaaa" }}>
                                {filtro ? "/ \"" + filtro + "\" / " : ""}
                                {"" + currentFolder.FullPathNotUpper(" / ")}
                            </Typography>
                        </div>
                    </Slide>
                }
                <div>
                    {!props.searching && !props.loadingFullData && currentFolder.Audiovisuales.length > 0 && currentFolder.Audiovisuales.sort((x, y) => x.Nombre === y.Nombre ? 0 : x.Nombre > y.Nombre ? 1 : -1).map((audiovisual: Audiovisual, index: number) => {
                        if (index >= 100) {
                            return null;
                        }

                        if (!audiovisual) {
                            return null;
                        }

                        return (
                            <AudiovisualItem
                                audiovisual={audiovisual}
                                key={audiovisual.Nombre}
                                audiovisualesApuntados={props.audiovisualesApuntados}
                                foldersApuntados={props.foldersApuntados}
                            />
                        );
                    })}
                    {!props.searching && !props.loadingFullData && currentFolder.Folders.size > 0 && Array.from(currentFolder.Folders.values()).sort((x, y) => x.Nombre === y.Nombre ? 0 : x.Nombre > y.Nombre ? 1 : -1).map((folder: Folder, index: number) => {
                        if (index >= 100) {
                            return null;
                        }

                        if (!folder) {
                            return null;
                        }

                        return (
                            <FolderItem
                                folder={folder}
                                key={folder.Nombre}
                                audiovisualesApuntados={props.audiovisualesApuntados}
                                foldersApuntados={props.foldersApuntados}
                            />
                        );
                    })}
                </div>
                {!props.searching && !props.loadingFullData && currentFolder.Audiovisuales.length === 0 && currentFolder.Folders.size === 0 &&
                    <Slide direction="right" in mountOnEnter>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                marginTop: 80,
                                alignItems: currentWidth === "xs" ? "center" : "flex-start",
                                justifyContent: "center",
                                marginLeft: currentWidth === "xs" ? 0 : 20
                            }}
                        >
                            <Typography style={{ color: "#dddddd" }} variant="h4">
                                No Resultados
                            </Typography>
                            <Button color="secondary" style={{ marginTop: 20 }} variant="outlined" onClick={() => { history.push("/"); }}>Explorar</Button>

                        </div>
                    </Slide>
                }
                {(props.searching || props.loadingFullData) && <CenteredLoading />
                }
            </div>
        </>
    );
}