import { AreSimilar } from "../Utils/StringsSimilarity";
import { ReplaceAll, ReplaceAllRegex } from "../Utils/StringUtils";
import { IsLetter, NoUpperCase } from "../Utils/Utils";
import { Audiovisual } from "./Audiovisual";
import { FolderApuntado } from "./FolderApuntado";
import { Tipo, TipoParseFromString } from "./Tipo";

export class Folder {
    Nombre = "";

    Folders: Map<string, Folder> = new Map<string, Folder>();

    Audiovisuales: Audiovisual[] = [];

    ParentFolder: Folder | null = null;

    TotalAudiovisuales: number = 0;

    TotalSizeInMb: number = 0;

    GetTotalSizeText(): string {
        return "" + (this.TotalSizeInMb / 1024).toFixed(2) + " GB";
    }

    GetCantidadDeAudiovisuales(): string {
        return "Audiovisuales " + this.TotalAudiovisuales;
    }

    GetEstaApuntada(foldersApuntados: Map<string, FolderApuntado>): boolean {
        if (foldersApuntados.size === 0) {
            return false;
        }

        var paths: string[] = [];
        var current: Folder | null = this;
        while (current != null) {
            paths.unshift(current.Nombre);
            current = current.ParentFolder;
        }

        var fullPath = "";
        while (paths.length > 0) {
            var currentPath = paths[0];
            paths.shift();
            fullPath += (currentPath + " / ");
            if (foldersApuntados.has(fullPath.toString())) {
                return true;
            }
        }

        return false;
    }

    GetEstaApuntadaParent(foldersApuntados: Map<string, FolderApuntado>): boolean {
        if (foldersApuntados.size === 0) {
            return false;
        }

        var paths: string[] = [];
        var current: Folder | null = this.ParentFolder;
        while (current != null) {
            paths.unshift(current.Nombre);
            current = current.ParentFolder;
        }

        var fullPath = "";
        while (paths.length > 0) {
            var currentPath = paths[0];
            paths.shift();
            fullPath += (currentPath + " / ");
            if (foldersApuntados.has(fullPath.toString())) {
                return true;
            }
        }

        return false;
    }

    GetEstaApuntadaDirectamente(foldersApuntados: Map<string, FolderApuntado>): boolean {
        return foldersApuntados.has(this.FullPath());
    }

    GetImagesURLToSearch(): string {
        var tipo = this.GetTipo();

        return "https://www.google.com/search?hl=es&tbm=isch&q="
            + (tipo !== Tipo.Indefinido && tipo !== Tipo.Interesante ? tipo.toString() + " " : "")
            + this.GetNombreQuery();
    }

    GetSinopsisURLToSearch(): string {
        var tipo = this.GetTipo();

        return "https://www.google.com/search?hl=es&q="
            + (tipo !== Tipo.Indefinido && tipo !== Tipo.Interesante ? tipo.toString() + " " : "")
            + + this.GetNombreQuery() + " Sinopsis";
    }

    GetTipo(): Tipo {
        var current: Folder = this;
        while (current.ParentFolder !== null) {
            current = current.ParentFolder;
        }

        return TipoParseFromString(current.Nombre);
    }

    NoEmpty() {
        return this.Audiovisuales.length + this.Folders.size > 0;
    }

    GetNombreQuery(): string {
        var nombre = this.GetNombreLongNotUpperToShow();
        if (!nombre) {
            return "";
        }

        var extensionInicio = nombre.lastIndexOf('.');
        if (extensionInicio < 0) {
            extensionInicio = nombre.length;
        }

        var result = "";
        var opened = 0;
        for (var i = 0; i < extensionInicio; i++) {
            var current = nombre.charAt(i);

            if (current === ')' || current === ']' || current === '}') {
                if (opened > 0) {
                    opened--;
                }
                continue;
            }

            if (current === '(' || current === '[' || current === '{') {
                opened++;
                continue;
            }

            if (opened > 0) {
                continue;
            }

            if (IsLetter(current)) {
                if (!((i > 0 && IsLetter(nombre.charAt(i - 1))) && (i < extensionInicio - 1 && IsLetter(nombre.charAt(i + 1))))) {
                    result += (("" + current));
                } else {
                    if (current === 'a' || current === 'y' || current === 'e' || current === 'o') {
                        result += (("" + current));
                    } else {
                        result += (" ");
                    }
                }
            } else {
                result += (" ");
            }
        }

        return result;
    }

    GetNombreLongNotUpperToShow(): string {
        return NoUpperCase(this.Nombre);
    }

    EsCleanTemporadaFolder(): boolean {
        try {
            var lower = this.Nombre.toLowerCase();

            if (lower.indexOf("season") < 0 && lower.indexOf("temp") < 0) {
                return false;
            }

            var clean = ReplaceAllRegex(lower, / |temporada|temp|season|\d|\(|\)|\[|\]|unica|única|caps|cap|capitulos|capítulos/, "")
                ;

            return clean.length <= 2;
        } catch (e) {
        }

        return false;
    }

    EsContainerDeSerieONovela(): boolean {
        try {
            if (this.EsCleanTemporadaFolder()) {
                return false;
            }

            if (this.Audiovisuales.length <= 2 && this.Folders.size <= 2 && this.Folders.size > 0) {
                var allTrue: boolean = true;
                this.Folders.forEach((k, v) => {
                    if (!k.EsCleanTemporadaFolder()) {
                        allTrue = false;
                    }
                });

                if (allTrue) {
                    return true;
                }
            }

            var folders: Folder[] = [];
            this.Folders.forEach((v, k) => { folders.push(v); })

            if (this.Audiovisuales.length <= 2 && this.Folders.size >= 3) {
                let similaritiesYes = 0;
                var cleanTemporadaFolders = folders[0].EsCleanTemporadaFolder() ? 1 : 0;
                for (let i = 1; i < this.Folders.size; i++) {
                    if (AreSimilar(folders[i - 1].Nombre.toLowerCase(), folders[i].Nombre.toLowerCase(), 0.2)) {
                        similaritiesYes++;
                    }

                    if (similaritiesYes >= 2) {
                        return true;
                    }

                    if (folders[i].EsCleanTemporadaFolder()) {
                        cleanTemporadaFolders++;
                    }

                    if (cleanTemporadaFolders >= 2) {
                        return true;
                    }
                }
            } else if (this.Folders.size <= 2 && this.Audiovisuales.length >= 3) {
                let similaritiesYes = 0;
                for (let i = 1; i < this.Audiovisuales.length; i++) {
                    if (AreSimilar(this.Audiovisuales[i - 1].GetNombreSinExt(), this.Audiovisuales[i].GetNombreSinExt(), 0.2)) {
                        similaritiesYes++;
                    }

                    if (similaritiesYes >= 2) {
                        return true;
                    }
                }
            }
        } catch (e) {

        }

        return false;
    }

    FullPath(separator: string = " / "): string {
        var result = "";
        var parents: string[] = [];
        var current: Folder | null = this;
        while (current != null) {
            if (current.Nombre) {
                parents.push(current.Nombre);
            }
            current = current.ParentFolder;
        }

        while (parents.length > 0) {
            result += (parents.pop() + separator);
        }

        return result.toString();
    }

    FullPathNotUpper(separator: string = " / ") {
        return NoUpperCase(this.FullPath(separator));
    }

    GetFolderApuntado(): FolderApuntado {
        var result = new FolderApuntado();
        result.FullPath = this.FullPath();
        result.TotalSizeInMb = this.TotalSizeInMb;
        result.TotalAudiovisuales = this.TotalAudiovisuales;

        return result;
    }

    FullPathToShow(): string {
        return NoUpperCase(this.FullPath());
    }

    BuildFolderApuntado(): FolderApuntado {
        var result = new FolderApuntado();
        result.FullPath = this.FullPath();
        result.TotalSizeInMb = this.TotalSizeInMb;
        result.TotalAudiovisuales = this.TotalAudiovisuales;

        return result;
    }
}
