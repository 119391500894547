import { AudiovisualApuntado } from "./AudiovisualApuntado";
import { FolderApuntado } from "./FolderApuntado";

export function GetAllAudiovisualesApuntadosFromStorage(): Map<string, AudiovisualApuntado> {
    var audiovisualesApuntados: AudiovisualApuntado[] = [];

    for (let index = 0; index < localStorage.length; index++) {
        const key = localStorage.key(index);

        if (key?.startsWith("aa-")) {
            try {
                var rawObject = localStorage[key];
                var object = JSON.parse(rawObject);
                audiovisualesApuntados.push(new AudiovisualApuntado(object));
            } catch (e) {

            }
        }
    }

    var result = new Map<string, AudiovisualApuntado>();

    audiovisualesApuntados.forEach(audiovisual => {
        result.set(audiovisual.FullPath, audiovisual);
    });

    return result;
}

export function GetAllFolderApuntadosFromStorage(): Map<string, FolderApuntado> {
    var foldersApuntados: FolderApuntado[] = [];

    for (let index = 0; index < localStorage.length; index++) {
        const key = localStorage.key(index);

        if (key?.startsWith("fa-")) {
            try {
                var rawObject = localStorage[key];
                var object = JSON.parse(rawObject);
                foldersApuntados.push(new FolderApuntado(object));
            } catch (e) {

            }
        }
    }

    var result = new Map<string, FolderApuntado>();

    foldersApuntados.forEach(folder => {
        result.set(folder.FullPath, folder);
    })

    return result;
}

export function EliminarTodoApuntadoStorage() {
    var keys: string[] = [];
    for (let index = 0; index < localStorage.length; index++) {
        const key = localStorage.key(index);

        if (key?.startsWith("fa-") || key?.startsWith("aa-")) {
            keys.push(key);
        }
    }

    keys.forEach(key => {
        localStorage.removeItem(key);
    });
}

export function AddAudiovisualApuntadoStorage(audiovisualApuntado: AudiovisualApuntado) {
    localStorage["aa-" + audiovisualApuntado.FullPath] = JSON.stringify(audiovisualApuntado);
}

export function AddFolderApuntadaStorage(folderApuntada: FolderApuntado) {
    localStorage["fa-" + folderApuntada.FullPath] = JSON.stringify(folderApuntada);
}

export function RemoveAudiovisualApuntadoStorage(fullPath: string) {
    try {
        localStorage.removeItem("aa-" + fullPath)
    } catch (e) {

    }
}

export function RemoveFolderApuntadoStorage(fullPath: string) {
    try {
        localStorage.removeItem("fa-" + fullPath)
    } catch (e) {

    }
}
