import { MouseEventHandler } from 'react';

import LocalMoviesIcon from '@material-ui/icons/LocalMovies';
import { Slide, Typography, Button, Divider } from "@material-ui/core/";
import CheckIcon from '@material-ui/icons/Check'
import { AudiovisualApuntado } from '../Modelos/AudiovisualApuntado';
import { GetRandomColor } from '../Utils/ColorHelper';


export default function AudiovisualApuntadoItem(props: {
    audiovisualApuntado: AudiovisualApuntado,
    removeApuntado: (a: AudiovisualApuntado) => void
}) {

    const handleClick: MouseEventHandler<HTMLButtonElement> | undefined = () => {
        props.removeApuntado(props.audiovisualApuntado);
    };

    return (
        <div>
            <Slide direction="right" in mountOnEnter>
                <div>
                    <Button
                        variant="text"
                        fullWidth
                        color="primary"
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            paddingRight: 0, paddingTop: 10, paddingLeft: 0, paddingBottom: 10,
                            textTransform: "none"
                        }}
                        onClick={handleClick}

                    >
                        <div style={{ flex: "auto", display: "flex", flexDirection: "row", alignItems: "stretch", paddingLeft: 8 }}>
                            <div style={{ flex: "0 0 auto", }}>
                                <LocalMoviesIcon htmlColor={GetRandomColor(props.audiovisualApuntado.GetNombreLongNotUpperToShow())} style={{ marginTop: 2, marginRight: 0, fontSize: 30 }} />
                            </div>
                            <div style={{ flex: "1 1 auto", display: "flex", flexDirection: "column", marginLeft: 8 }}>
                                <div style={{ display: "flex", justifyContent: "flex-start", marginTop: 0, marginRight: 40 }}>
                                    <Typography variant="h6" align="left" style={{ fontSize: 14, fontWeight: "lighter", color: "#999999" }}>
                                        {props.audiovisualApuntado.GetNombreLongNotUpperToShow()}
                                    </Typography>
                                </div>
                                <div style={{ display: "flex", alignItems: "center", marginTop: 1 }}>
                                    <Typography style={{ fontSize: 10, fontWeight: "lighter", color: "#cccccc" }}>
                                        {props.audiovisualApuntado.GetGBSizeString()}
                                    </Typography>
                                    <Typography style={{ fontSize: 10, fontWeight: "lighter", color: "#cccccc", marginLeft: 10 }}>
                                        {props.audiovisualApuntado.GetDuracionString()}
                                    </Typography>
                                </div>
                            </div>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <div style={{ flex: 1, display: "flex", flexDirection: "row-reverse", marginRight: 15 }}>
                                    <CheckIcon style={{ fontSize: 22, color: "#EF9A9A" }} />
                                </div>
                            </div>
                        </div>
                    </Button>
                    <Divider light style={{ marginLeft: 8, marginRight: 8 }} />
                </div>
            </Slide>
        </div>
    );
}