import React from "react";
import { CircularProgress } from "@material-ui/core";

export default function CenteredLoading() {
  return (

    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        position: "absolute",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      }}
    >
      <CircularProgress size={40} style={{ marginBottom: 40 }} disableShrink color="secondary" />
    </div>
  );
}
