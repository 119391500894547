import { Folder } from "./Folder";
import { Tipo, TipoText } from "./Tipo";

export function FindFolderByFullPath(data: Folder, fullPath: string): Folder {
    if (!fullPath) {
        return data;
    }

    if (fullPath === data.Nombre) {
        return data;
    }

    var paths = fullPath.split(PathSeparator);

    var result = data;

    for (let index = 0; index < paths.length; index++) {
        const element = paths[index];
        if (!element) {
            continue;
        }
        result = result.Folders.get(element) || new Folder();
    }

    return result;
}

function FiltrarRec(folder: Folder, tokens: string[], filtrado: Folder): void {
    if (Match(tokens, folder.Nombre)) {
        filtrado.Folders.set(folder.Nombre, folder);
    } else {
        for (let index = 0; index < folder.Audiovisuales.length; index++) {
            const audiovisual = folder.Audiovisuales[index];
            if (Match(tokens, audiovisual.Nombre)) {
                filtrado.Audiovisuales.push(audiovisual);

                if (filtrado.Folders.size + filtrado.Audiovisuales.length > maxResultsForSearch) {
                    return;
                }
            }
        }

        var subFolders = Array.from(folder.Folders.values());
        for (let index = 0; index < subFolders.length; index++) {
            const folderChild = subFolders[index];
            FiltrarRec(folderChild, tokens, filtrado);

            if (filtrado.Folders.size + filtrado.Audiovisuales.length > maxResultsForSearch) {
                return;
            }
        }
    }
}

const maxResultsForSearch = 20;


export function Search(fullData: Folder, filtro: string, tipo: Tipo): Folder {
    if (!filtro && tipo === Tipo.Indefinido) {
        return new Folder();
    }

    var filtrado = new Folder();

    if (!filtro) {
        var tipoString = TipoText(tipo);
        filtrado.Folders.set(tipoString, fullData.Folders.get(tipoString) || new Folder());
    } else {
        var tokens = GetTokensFilters(filtro);

        if (tipo === Tipo.Indefinido) {
            FiltrarRec(fullData, tokens, filtrado);
        } else {
            var tipoText = TipoText(tipo);
            filtrado.Nombre = tipoText;
            FiltrarRec(fullData.Folders.get(tipoText) || new Folder(), tokens, filtrado);
        }
    }

    return filtrado;
}

function Match(tokens: string[], s: string) {
    var lower = s.toLowerCase();
    for (let index = 0; index < tokens.length; index++) {
        const element = tokens[index];
        if (lower.indexOf(element) < 0) {
            return false;
        }
    }

    return true;
}

function GetTokensFilters(s: string): string[] {
    if (!s) {
        return [];
    }

    var textFilters = s.toLowerCase().split(" ");
    var clean: string[] = [];

    for (let index = 0; index < textFilters.length; index++) {
        const element = textFilters[index];
        if (stopWords.indexOf(element) < 0) {
            clean.push(element);
        }
    }

    if (clean.length >= 2) {
        return clean;
    }

    return textFilters;
}

const stopWords = ["un", "una", "la", "el", "y", "que", "al", "con", "de", "a", "en", "lo", "o", "se", "si"];

export const PathSeparator = "---";