import { useEffect, useState } from 'react';
import './App.css';
import AppThemer from './Theme/AppThemer';
import { CssBaseline } from '@material-ui/core';
import JSZip from 'jszip';
import {
  HashRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import HomeScreen from './HomeScreen/HomeScreen';
import { AudiovisualesDataLoader } from './Modelos/AudiovisualesDataLoader';
import { BusinessInfo } from './Modelos/BusinessInfo';
import { Tipo, TipoParseFromString, TipoText } from './Modelos/Tipo';
import { Folder } from './Modelos/Folder';
import { AudiovisualApuntado } from './Modelos/AudiovisualApuntado';
import { FolderApuntado } from './Modelos/FolderApuntado';
import { AudiovisualesScreen } from './AudiovisualesScreen/AudiovisualesScreen';
import { PathSeparator, Search } from './Modelos/Search';
import { GetAllAudiovisualesApuntadosFromStorage, GetAllFolderApuntadosFromStorage, RemoveAudiovisualApuntadoStorage, RemoveFolderApuntadoStorage } from './Modelos/ApuntadoStorageHelper';
import { GetParam } from './Utils/QueryParams';
import ApuntadoScreen from './ApuntadoScreen/ApuntadoScreen';

export default function App() {
  const [businessInfo, setBusinessInfo] = useState(new BusinessInfo());

  const [loadingFullData, setLoadingFullData] = useState(true);

  const [fullData, setFullData] = useState(new Folder());

  const [searching, setSearching] = useState(false);

  const [filteredData, setFilteredData] = useState(new Folder());

  const [filtroText, setFiltroText] = useState("");

  const [filtroTipo, setFiltroTipo] = useState(Tipo.Pelicula);

  const [audiovisualesApuntados, setAudiovisualesApuntados] = useState<Map<string, AudiovisualApuntado>>(new Map());

  const [foldersApuntados, setFoldersApuntados] = useState<Map<string, FolderApuntado>>(new Map());

  useEffect(() => {
    var fetchDataFunction = async () => {
      var businessInfo = await FetchBusinessInformation();
      setBusinessInfo(businessInfo);
      var data = await FetchData();
      if (data) {
        console.log("Raw");
        console.log(data);

        var dataLoader = new AudiovisualesDataLoader();
        dataLoader.Load(data, setFullData, setFilteredData);
        console.log("Loaded And Filtered");
        console.log(dataLoader.Data);

        setAudiovisualesApuntados(GetAllAudiovisualesApuntadosFromStorage());
        setFoldersApuntados(GetAllFolderApuntadosFromStorage());

        //caso que se entre directo por la url de una busqueda.
        if (window.location.hash.startsWith("#/sub?")) {
          setSearching(true);
          var filtroTextParam = GetParam("filtro");
          var path = GetParam("path");
          var pathSplitted = path ? path.split(PathSeparator) : [];
          var filtroTipoParam = pathSplitted && pathSplitted.length > 0 ? TipoParseFromString(pathSplitted[0]) : Tipo.Indefinido;
          var filteredData = Search(dataLoader.Data, filtroTextParam, filtroTipoParam);
          setFilteredData(filteredData);
          setSearching(false);
          console.log("Custom Filtered");
        }

        setLoadingFullData(false);
      }
    };

    fetchDataFunction();

  }, []);

  const search = (filtroTipoParam: Tipo, filtroTextParam: string) => {
    setSearching(true);

    if (filtroTipoParam !== Tipo.Indefinido) {
      setFiltroTipo(filtroTipoParam);
    }

    setFiltroText(filtroTextParam);


    var queryString = "";
    if (filtroTextParam) {
      queryString += "filtro=" + filtroTextParam;
    }

    if (filtroTipoParam !== Tipo.Indefinido) {
      if (queryString.length > 1) {
        queryString += "&";
      }

      queryString += "path=" + TipoText(filtroTipoParam);
    }

    if (!loadingFullData) {
      var filteredData = Search(fullData, filtroTextParam, filtroTipoParam);
      setFilteredData(filteredData);
      setSearching(false);
    }

    window.location.hash = "/sub?" + queryString;
  }

  const removeAudiovisualApuntado = (audiovisualApuntado: AudiovisualApuntado) => {
    var clone = new Map<string, AudiovisualApuntado>();
    Array.from(audiovisualesApuntados.entries()).forEach(element => {
      clone.set(element[0], element[1]);
    });

    clone.delete(audiovisualApuntado.FullPath);
    setAudiovisualesApuntados(clone);
    RemoveAudiovisualApuntadoStorage(audiovisualApuntado.FullPath)
  };

  const removeFolderApuntado = (folderApuntado: FolderApuntado) => {
    var clone = new Map<string, FolderApuntado>();
    Array.from(foldersApuntados.entries()).forEach(element => {
      clone.set(element[0], element[1]);
    });

    clone.delete(folderApuntado.FullPath);
    setFoldersApuntados(clone);
    RemoveFolderApuntadoStorage(folderApuntado.FullPath)
  };

  const desapuntarTodo = () => {
    setAudiovisualesApuntados(new Map());
    setFoldersApuntados(new Map());
  };


  return (
    <>
      <CssBaseline />
      <AppThemer>
        <Router>
          <Switch>
            <Route path="/sub">
              <AudiovisualesScreen
                businessInfo={businessInfo}
                apuntados={audiovisualesApuntados.size + foldersApuntados.size}
                loadingFullData={loadingFullData}
                searching={searching}
                filteredData={filteredData}
                fullData={fullData}
                search={search}
                audiovisualesApuntados={audiovisualesApuntados}
                foldersApuntados={foldersApuntados}
              />
            </Route>
            <Route path="/apuntado">
              <ApuntadoScreen
                businessInfo={businessInfo}
                audiovisualesApuntados={audiovisualesApuntados}
                foldersApuntados={foldersApuntados}
                removeAudiovisualApuntado={removeAudiovisualApuntado}
                removeFolderApuntado={removeFolderApuntado}
                desapuntarTodo={desapuntarTodo}
              />
            </Route>
            <Route path="/">
              <HomeScreen
                filtroTipo={filtroTipo}
                setFiltroTipo={setFiltroTipo}
                filtroText={filtroText}
                setFiltroText={setFiltroText}
                businessInfo={businessInfo}
                audiovisualesApuntados={audiovisualesApuntados}
                foldersApuntados={foldersApuntados}
                search={search}
                tiposPresentes={Array.from(fullData.Folders.keys()).map(x => TipoParseFromString(x))}
              />
            </Route>
            <Redirect
              to="/" />
          </Switch>
        </Router>
      </AppThemer>
    </>
  );
}

async function FetchData(): Promise<any[]> {
  var blob = await FetchZip();
  var zip = new JSZip();
  var zipped = await zip.loadAsync(blob);

  var result: any[] = [];

  for (var file in zipped.files) {
    var currentFile = zipped.file(file);
    if (currentFile && !currentFile.dir) {
      var jsonFile = await currentFile.async("text");
      result.push(JSON.parse(jsonFile));
    }
  }

  return result;
}

async function FetchZip() {
  var response = await fetch("Informacion.zip");
  var blob = await response.blob();
  return blob;
}

async function FetchBusinessInformation(): Promise<any> {
  var response = await fetch("NegocioInformacion.txt");
  var text = await response.text();
  return new BusinessInfo(JSON.parse(text));
}
