import { NoUpperCase } from "../Utils/Utils";

export class AudiovisualApuntado {
    FullPath = "";

    SizeMB = 0;

    Duracion = "";

    constructor(x: any = {}) {
        this.FullPath = (x && x.FullPath) || "";
        this.SizeMB = (x && x.SizeMB) || 0;
        this.Duracion = (x && x.Duracion) || "";
    }

    GetNombreLongNotUpperToShow(): string {
        return NoUpperCase(this.FullPath);
    }

    GetGBSizeString() {
        try {
            return ((this.SizeMB) / 1024).toFixed(2) + " GB";
        } catch (e) {

        }

        return "";
    }

    GetDuracionString(): string {
        if (!this.Duracion || this.Duracion.length > 20) {
            return "";
        }

        return this.Duracion;
    }

    GetApuntado(audiovisualesApuntados: Map<string, AudiovisualApuntado>) {
        return audiovisualesApuntados.has(this.FullPath);
    }
}
