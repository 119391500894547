import { useState, MouseEventHandler } from 'react';

import LocalMoviesIcon from '@material-ui/icons/LocalMovies';
import { Slide, Typography, Button, Divider } from "@material-ui/core/";
import CheckIcon from '@material-ui/icons/Check'
import { Audiovisual } from '../Modelos/Audiovisual';
import { Tipo } from '../Modelos/Tipo';
import { GetRandomColor } from '../Utils/ColorHelper';
import { AudiovisualApuntado } from '../Modelos/AudiovisualApuntado';
import { FolderApuntado } from '../Modelos/FolderApuntado';
import { AddAudiovisualApuntadoStorage, RemoveAudiovisualApuntadoStorage } from '../Modelos/ApuntadoStorageHelper';
import { updateCarritoNumberManual } from '../Utils/Utils';


export default function AudiovisualItem(props: {
    audiovisual: Audiovisual,
    audiovisualesApuntados: Map<string, AudiovisualApuntado>,
    foldersApuntados: Map<string, FolderApuntado>,
}) {

    const [apuntado, setApuntado] = useState(props.audiovisual.GetEstaApuntado(props.audiovisualesApuntados, props.foldersApuntados));

    const handleClick: MouseEventHandler<HTMLButtonElement> | undefined = () => {
        if (apuntado) {
            if (props.audiovisual.GetEstoyApuntadoYoDirectamente(props.audiovisualesApuntados)) {
                props.audiovisualesApuntados.delete(props.audiovisual.FullPath());
                RemoveAudiovisualApuntadoStorage(props.audiovisual.FullPath());
                updateCarritoNumberManual(props.audiovisualesApuntados.size + props.foldersApuntados.size);
                setApuntado(!apuntado);
            } else {
                //todo: mostrar snack de que desmarque la carpeta padre que lo tiene marcado a el
            }
        } else {
            var audiovisualApuntado = props.audiovisual.BuildAudiovisualApuntado();
            props.audiovisualesApuntados.set(props.audiovisual.FullPath(), audiovisualApuntado);
            AddAudiovisualApuntadoStorage(audiovisualApuntado);
            updateCarritoNumberManual(props.audiovisualesApuntados.size + props.foldersApuntados.size);
            setApuntado(!apuntado);
        }

    };


    return (
        <div>
            <Slide direction="right" in mountOnEnter>
                <div>
                    <Button
                        variant="text"
                        fullWidth
                        color="primary"
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            paddingRight: 0, paddingTop: 10, paddingLeft: 0, paddingBottom: 10,
                            textTransform: "none"
                        }}
                        onClick={handleClick}

                    >
                        <div style={{ flex: "auto", display: "flex", flexDirection: "row", alignItems: "stretch", paddingLeft: 8 }}>
                            <div style={{ flex: "0 0 auto", }}>
                                <LocalMoviesIcon htmlColor={GetRandomColor(props.audiovisual.GetNombreLongNotUpperToShow())} style={{ marginTop: 2, marginRight: 0, fontSize: 30 }} />
                            </div>
                            <div style={{ flex: "1 1 auto", display: "flex", flexDirection: "column", marginLeft: 8 }}>
                                <div style={{ display: "flex", justifyContent: "flex-start", marginTop: 0, marginRight: 40 }}>
                                    <Typography variant="h6" align="left" style={{ fontSize: 14, fontWeight: "lighter", color: "#999999" }}>
                                        {props.audiovisual.GetNombreLongNotUpperToShow()}
                                    </Typography>
                                </div>
                                <div style={{ display: "flex", alignItems: "center", marginTop: 1 }}>
                                    <Typography style={{ fontSize: 10, fontWeight: "lighter", color: "#cccccc" }}>
                                        {props.audiovisual.GetGBSizeString()}
                                    </Typography>
                                    <Typography style={{ fontSize: 10, fontWeight: "lighter", color: "#cccccc", marginLeft: 10 }}>
                                        {props.audiovisual.GetDuracionString()}
                                    </Typography>
                                </div>
                                {props.audiovisual.GetTipo() === Tipo.Pelicula &&
                                    <div style={{ flex: 1, display: "flex", flexDirection: "row", marginBottom: -10, marginLeft: -16, marginTop: -2 }}>
                                        <Button style={{ padding: 0, marginRight: -16 }} target="_blank" href={props.audiovisual.GetSinopsisURLToSearch()}>
                                            <Typography style={{ fontSize: 8, color: "#dddddd", textTransform: "none", }}>
                                                Sinopsis
                                            </Typography>
                                        </Button>
                                        <Button style={{ marginRight: -16, textTransform: "none" }} target="_blank" href={props.audiovisual.GetImagesURLToSearch()}>
                                            <Typography style={{ fontSize: 8, color: "#dddddd", margin: 0, padding: 0 }}>
                                                Imágenes
                                            </Typography>
                                        </Button>
                                    </div>}
                            </div>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <div style={{ flex: 1, display: "flex", flexDirection: "row-reverse", marginRight: 15 }}>
                                    {apuntado && <CheckIcon style={{ fontSize: 22, color: "#EF9A9A" }} />}
                                </div>
                            </div>
                        </div>
                    </Button>
                    <Divider light style={{ marginLeft: 8, marginRight: 8 }} />
                </div>
            </Slide>
        </div>
    );
}
