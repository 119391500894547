export function GetParam(param: string): string {
    let query = window.location.hash;
    let start = query.indexOf("?");
    if (start < 0 || start >= query.length - 1) {
        return "";
    }

    query = query.substr(start + 1);

    let splitted = query.split("&");

    for (let i = 0; i < splitted.length; i++) {
        const current = splitted[i];
        if (current.startsWith(param + "=")) {
            return decodeURI(current.substr(param.length + 1));
        }
    }

    return "";
}