import { IconButton } from '@material-ui/core';
import React, { MouseEventHandler } from 'react';
import { ArrowBack } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';

export default function BackButton() {

    let history = useHistory();

    const onBackClick: MouseEventHandler<HTMLElement> = () => {
        history.goBack();
    }

    return (
        <IconButton onClick={onBackClick}>
            <ArrowBack style={{ color: "#aaaaaa" }} />
        </IconButton>
    );
}