export class BusinessInfo {
    Nombre = "";

    MarketingTexto = "";

    Direccion = "";

    GetDireccionFormatted() {
        if (!this.Direccion) {
            return "De aquí del Barrio.";
        }

        return this.Direccion.replace("&&&", "\n");
    }

    constructor(x: any = {}) {
        this.Nombre = (x && x.Nombre) || "";
        this.MarketingTexto = (x && x.MarketingTexto) || "";
        this.Direccion = (x && x.Direccion) || "";
    }
}
