export function NoUpperCase(s: string): string {
    var trimmed = s.trim();
    var result = "";
    for (var i = 0; i < trimmed.length; i++) {
        var current = trimmed.charAt(i);
        if (!IsLetter(current)) {
            result = result.concat(current);
        } else {
            if (IsLowerCase(current)) {
                if (i === 0) {
                    result = result.concat(("" + current).toUpperCase());
                } else {
                    result = result.concat(current);
                }
            } else {
                if (i === 0) {
                    result = result.concat(current);
                } else {
                    var previous = trimmed.charAt(i - 1);
                    if (IsLetter(previous) && !IsLowerCase(previous)) {
                        result = result.concat(("" + current).toLowerCase());
                    } else {
                        result = result.concat(current);
                    }
                }
            }
        }
    }

    return result.toString();
}

export function IsLetter(c: string): boolean {
    return c.length === 1 && c.match(/[a-z]/i) != null;
}

export function IsLowerCase(c: string): boolean {
    return c.toLowerCase() === c;
}

export function OnlyLetters(s: string): string {
    var result = "";
    for (var i = 0; i < s.length; i++) {
        var current = s.charAt(i);
        if (IsLetter(current)) {
            result = result.concat(current);
        }
    };

    return result.toLowerCase();
}

export function BoundName(nombre: string, n: number): string {
    if (!nombre) {
        return "";
    }

    if (nombre.length <= n) {
        return nombre;
    }

    return nombre.substring(0, n - 10) + ".." + nombre.substring(nombre.length - 10);
}

export function RemoveExtension(s: string): string {
    var lastIndexOf: number = s.lastIndexOf('.');
    if (lastIndexOf > 0) {
        return s.toLowerCase().substring(0, lastIndexOf);
    } else {
        return s.toLowerCase();
    }
}

export function updateCarritoNumberManual(n: number) {
    var button = document.getElementById("apuntadoButton");
    if (button) {
        var spans = button.getElementsByClassName("MuiBadge-anchorOriginTopRightRectangle");
        if (spans) {
            var span = spans.item(0);
            if (span) {
                span.innerHTML = "" + n;
            }
        }
    }
}