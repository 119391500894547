import { MouseEventHandler } from 'react';
import { IconButton, Tooltip, Badge } from '@material-ui/core';
import ShopingCartIcon from '@material-ui/icons/ShoppingCart'
import { useHistory } from 'react-router-dom';

export default function ApuntadoButton(props: {
    apuntados: number
}) {
    const history = useHistory();
    const onClick: MouseEventHandler<HTMLButtonElement> | undefined = () => {
        history.push("/apuntado");
    }

    return (
        <div style={{
            display: "flex",
            alignItems: "center",
        }}>
            <Tooltip title="Apuntado.">
                <IconButton style={{ marginRight: 4 }} onClick={onClick} id={"apuntadoButton"}>
                    <Badge badgeContent={props.apuntados} showZero color="secondary">
                        <ShopingCartIcon style={{ fontSize: 18, color: "#888888" }} />
                    </Badge>
                </IconButton>
            </Tooltip>
        </div>
    );
}